/***

**Module Name: client
 **File Name :  client.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains clients table details.
 ***/
import React, { useState, useEffect, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import { useHistory, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import SessionPopup from "../SessionPopup"
import Loader from "../../components/loader";
import Tooltip from '@mui/material/Tooltip'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TableLoader from "../../components/tableLoader";
import { contentContext } from "../../context/contentContext";
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

let { lambda, appname } = window.app;


const DashBoard = () => {
    const history = useHistory();
    const { state } = useLocation();
    const { search } = state || {};
    // const [toggle, setToggle] = useState(false);
    const [deals, setDeals] = useState("");
    const [perpage, setPerpage] = useState(10);
    const [success, setSuccess] = useState(false);
    const [updateLead, setUpdateLead] = useState(false)
    const [loaderEnable, setLoaderEnable] = useState(false);


    const [totalCount, setTotalCount] = useState("")
    // const [data, setData] = useState([]);



    const [countries, setCountries] = useState('');

    // const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);


    // const [paginationnumber, setPagintionNumber] = useState({});

    const [popup, setShowPopup] = useState(false);
    const [showExists, setShowExists] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const [conversationpopup, setConversationpopup] = useState(false);
    const [addComments, setAddComments] = useState("");
    const handleClosePopup = () => setShowPopup(false);

    const handleCloseConversation = () => setConversationpopup(false)
    const handleCloseAddPopup = () => setAddPopup(false);
    const [sortDirection, setSortDirection] = useState('asc');
    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum] = useState();
    const [tableErrMsg, setTableErrMsg] = useState(false);
    const [image, setImg] = useState('');



    const { searchedFlag, setSearchedFlag, isLoading, setActiveMenuObj, setNavigateToggle, setIsLoading, processObject, sortTableAlpha, arrow, sortTableByDate, userData, dealsearch, setDealSearch, dealsadvSearch, setDealsAdvSearch, currentPage, setcurrentPage, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, setActiveMenuId, GetTimeActivity } = useContext(contentContext)



    // var exportExcelData = ["kiran"]

    const prevRoute = usePrevious(route)


    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
            setDealsAdvSearch({})
            setSearchedFlag(false);
            setDealSearch("")


        }


    }, [prevRoute]);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);



    const GetCountries = async () => {
        try {

            const response = await tmdbApi.getLookUp({
                "type": ["country"],
                "sortBy": "alpha3",
                "projection": "tiny"
            });

            if (response?.result?.data == "Invalid token or Expired") {
                setShowSessionPopupup(true)
            } else {
                setCountries(response?.result?.data);
            }
        } catch {
        }
    };











    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setActiveMenuId(200000)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management": false,
            "Reports": false
        })
        setRoute("dashboard")
        GetCountries();
        setNavigateToggle("")

        //  getBuyerList();
        counts();
        userActivity();
    }, []);

    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        setIsLoading(true)
        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)

                }
            });
    }
    const counts = (e) => {

        GetTimeActivity()
        //  let page = 1;
        //  setCurrentPage(1);
        // setIsLoading(true);
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        axios({
            method: 'POST',
            url: lambda + '/totalCount?appname=' + appname + "&token=" + token + "&userid=" + userid,
        })
            .then(function (response) {

                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setData(response?.data?.result?.data);
                    // global.exportExcelData = response?.data?.result?.data
                    setTotalCount(response?.data?.result)
                    setIsLoading(false)

                    //  setContent(response?.data?.result?.data);
                    // setIsLoading(false);
                    // setClientMangeSearch("");
                    setSearchedFlag(false);
                }

            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    }

    const viewsCount = (count) => {
        if (count === undefined || count === null) {
            return '0'; // or any other default value you prefer
        }

        if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + 'm';
        } else {
            return count.toLocaleString();
        }
    };
    const handleBack = (value) => {
        if (value === "leads") {

            history.push("/leadmanagement")
        } else if (value === "deals") {
            history.push("/dealmanagement")
        } else if (value === "content") {
            history.push("/contentmanagement")
        } else if (value === "clients") {
            history.push("/clients")
        } else if (value === "company") {
            history.push("/company")
        }
    }

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">


                    <div className="page-content">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 className="mb-2 card-title">Dashboard</h4>
                                </div>
                                {/* <!--end col--> */}
                            </div>
                            <div className="row table-data">
                                <div className="col-12">

                                    <div className={isLoading ? "card" : "card dashboard-main"}>
                                        <div className="card-body dashboard-loader">
                                            {isLoading ? <TableLoader /> :
                                                <>
                                                    <div className="row" >
                                                        <div className="col5 col-sm-6" onClick={() => handleBack("leads")}>
                                                            <div className="card dashboard-card">
                                                                <div className="card-wrapper">
                                                                    <div className="icon-box">
                                                                        <img src={image+"orasi/admin/resources/orasiv1/images/leads-icon.svg"} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h1>{viewsCount(totalCount?.totalConversations) ? viewsCount(totalCount?.totalConversations) : 0}</h1>

                                                                        <h6>CONVERSATIONS</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}

                                                        <div className="col5 col-sm-6" onClick={() => handleBack("company")}>
                                                            <div className="card dashboard-card">
                                                                <div className="card-wrapper">
                                                                    <div className="icon-box">
                                                                        <img src={image+"orasi/admin/resources/orasiv1/images/company-icon.svg"} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h1>{viewsCount(totalCount?.totalCompanies)}</h1>
                                                                        <h6>TOTAL COMPANIES</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col5 col-sm-6" onClick={() => handleBack("deals")}>
                                                            <div className="card dashboard-card">
                                                                <div className="card-wrapper">
                                                                    <div className="icon-box">
                                                                        <img src={image+"orasi/admin/resources/orasiv1/images/deal-icon.svg"} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h1>{viewsCount(totalCount?.totalDeals)}</h1>
                                                                        <h6>TOTAL DEALS</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col5 col-sm-6" onClick={() => handleBack("content")}>
                                                            <div className="card dashboard-card">
                                                                <div className="card-wrapper">
                                                                    <div className="icon-box">
                                                                        <img src={image+"orasi/admin/resources/orasiv1/images/content-icon.svg"} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h1>{viewsCount(totalCount?.totalContent)}</h1>
                                                                        <h6>TOTAL CONTENT</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col5 col-sm-6" onClick={() => handleBack("clients")}>
                                                            <div className="card dashboard-card">
                                                                <div className="card-wrapper">
                                                                    <div className="icon-box">
                                                                        <img src={image+"orasi/admin/resources/orasiv1/images/clients-icon.svg"} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h1>{viewsCount(totalCount?.totalClients)}</h1>
                                                                        <h6>TOTAL CLIENTS</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}

                                                    {/* <!-- First Row --> */}
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">

                                                                    <h6 className="widget-heading mb-2">LEADS CONVERSATION SUMMARY</h6>
                                                                    <div className="d-flex align-items-center justify-content-between leads_counts">
                                                                        <div className="d-flex align-items-center justify-content-between counts_block">
                                                                            <p className="captilize">Open</p>
                                                                            <p className="text-right">{viewsCount(totalCount?.lead?.open) ? viewsCount(totalCount?.lead?.open) : 0}</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-between counts_block right_block">
                                                                            <p className="captilize">Closed</p>
                                                                            <p className="text-right">{viewsCount(totalCount?.lead?.close) ? viewsCount(totalCount?.lead?.close) : 0}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">

                                                                    <h6 className="widget-heading mb-2">Companies Summary</h6>
                                                                    <div className="d-flex align-items-center justify-content-between leads_counts">
                                                                        <div className="d-flex align-items-center justify-content-between counts_block">
                                                                            <p className="captilize">Active</p>
                                                                            <p className="text-right">{viewsCount(totalCount?.company?.ACTIVE) ? viewsCount(totalCount?.company?.ACTIVE) : 0}</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-between counts_block right_block">
                                                                            <p className="captilize">Inactive</p>
                                                                            <p className="text-right">{viewsCount(totalCount?.company?.INACTIVE) ? viewsCount(totalCount?.company?.INACTIVE) : 0}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            {/* <!-- Leads Summary --> */}
                                                                            <h6 className="widget-heading mb-2">Buyer Summary</h6>
                                                                            <table className="table table-striped summary-table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" className="text-left">Status</th>
                                                                                        <th scope="col" className="text-right">Count</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td scope="row" className="text-left">Pending terms</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.['PENDING TERMS']) ? viewsCount(totalCount?.buyers?.['PENDING TERMS']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Active</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.ACTIVE) ? viewsCount(totalCount?.buyers?.ACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Rejected</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.REJECT) ? viewsCount(totalCount?.buyers?.REJECT) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Approval</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.['PENDING APPROVAL']) ? viewsCount(totalCount?.buyers?.['PENDING APPROVAL']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Inactive</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.INACTIVE) ? viewsCount(totalCount?.buyers?.INACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Verification</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.buyers?.['PENDING VERIFICATION']) ? viewsCount(totalCount?.buyers?.['PENDING VERIFICATION']) : 0}</td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <div className="card full-ht">
                                                                        <div className="card-body">
                                                                            {/* <!-- Deals Summary --> */}
                                                                            <h6 className="widget-heading mb-2">SELLERS  SUMMARY</h6>
                                                                            <table className="table table-striped summary-table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" className="text-left">Status</th>
                                                                                        <th scope="col" className="text-right">Count</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td scope="row" className="text-left">Pending terms</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.['PENDING TERMS']) ? viewsCount(totalCount?.seller?.['PENDING TERMS']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Active</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.ACTIVE) ? viewsCount(totalCount?.seller?.ACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Rejected</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.REJECT) ? viewsCount(totalCount?.seller?.REJECT) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Approval</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.['PENDING APPROVAL']) ? viewsCount(totalCount?.seller?.['PENDING APPROVAL']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Inactive</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.INACTIVE) ? viewsCount(totalCount?.seller?.INACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Verification</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.seller?.['PENDING VERIFICATION']) ? viewsCount(totalCount?.seller?.['PENDING VERIFICATION']) : 0}</td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <div className="card full-ht">
                                                                        <div className="card-body">
                                                                            {/* <!-- Content Summary --> */}
                                                                            <h6 className="widget-heading mb-2">BUYERS/SELLERS SUMMARY</h6>
                                                                            <table className="table table-striped summary-table mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" className="text-left">Status</th>
                                                                                        <th scope="col" className="text-right">Count</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr >
                                                                                        <td scope="row" className="text-left">Pending terms</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.['PENDING TERMS']) ? viewsCount(totalCount?.both?.['PENDING TERMS']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Active</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.ACTIVE) ? viewsCount(totalCount?.both?.ACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Rejected</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.REJECT) ? viewsCount(totalCount?.both?.REJECT) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Approval</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.['PENDING APPROVAL']) ? viewsCount(totalCount?.both?.['PENDING APPROVAL']) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Inactive</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.INACTIVE) ? viewsCount(totalCount?.both?.INACTIVE) : 0}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td scope="row" className="text-left">Pending Verification</td>
                                                                                        <td className="text-right">{viewsCount(totalCount?.both?.['PENDING VERIFICATION']) ? viewsCount(totalCount?.both?.['PENDING VERIFICATION']) : 0}</td>
                                                                                    </tr>


                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>
                                                            </div>
                                                            {/* <!-- Second Row --> */}
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h6 className="widget-heading mb-4">TOP SEARCHED CATEGORIES</h6>
                                                                            <div className="graph-blocks">
                                                                                <ul className="graph-list">
                                                                                    {(totalCount?.searchedCategory || [])?.map((item) => {

                                                                                        return (
                                                                                            <li>
                                                                                                <div className="graph-wrapper">
                                                                                                    <p>{item?.category.split(" ").map((name, index) => index === 0 || index === 1 ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : name.toLowerCase()).join(" ")}</p>

                                                                                                    <div className="graph-bar">
                                                                                                        <p className="count">{viewsCount(item?.count)}</p>
                                                                                                        <span className="arrow material-symbols-outlined">chevron_right</span>
                                                                                                        <div className="filled-graph" style={{ width: `${(item.count / totalCount.totalCategory) * 100}%` }}></div>
                                                                                                    </div>
                                                                                                    <div className="sub-graphs">
                                                                                                        <div className="triangle-wrapper">
                                                                                                            <div className="triangle"></div>
                                                                                                            <div className="triangle2"></div>
                                                                                                        </div>

                                                                                                        <div className="graph-blocks">
                                                                                                            <ul className="graph-list">
                                                                                                                {(item?.genres || []).map((genre, index) => {
                                                                                                                    const genrePercentage = (genre.count / item.count) * 100;
                                                                                                                    return (
                                                                                                                        <li key={index}>
                                                                                                                            <div className="graph-wrapper">
                                                                                                                                <p>{genre.genre === "Documentary/DocuSeries" ? "Documentary / DocuSeries" : genre.genre}</p>
                                                                                                                                <div className="graph-bar">
                                                                                                                                    <p className="count">{viewsCount(genre.count)}</p>
                                                                                                                                    <div className="filled-graph filled-sm" style={{ width: `${genrePercentage}%` }}></div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                })}
                                                                                                                {[...Array(Math.max(5 - (item?.genres?.length || 0), 0))].map((_, index) => (
                                                                                                                    <li key={`empty-${index}`}>
                                                                                                                        <div className="graph-wrapper">
                                                                                                                            <p>NA</p>
                                                                                                                            <div className="graph-bar">
                                                                                                                                <p className="count">0</p>
                                                                                                                                <div className="filled-graph" style={{ width: `0%` }}></div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </li>
                                                                                                                ))}
                                                                                                            </ul>



                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>

                                                                                        )
                                                                                    })}
                                                                                    {[...Array(5 - (totalCount?.searchedCategory?.length || 0))].map((_, index) => (
                                                                                        <li key={`empty-${index}`}>
                                                                                            <div className="graph-wrapper">
                                                                                                <p>NA</p>
                                                                                                <div className="graph-bar">
                                                                                                    <p className="count">0</p>
                                                                                                    <div className="filled-graph" style={{ width: `0%` }}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h6 className="widget-heading mb-4">TOP SEARCHED GENRES</h6>
                                                                            <div className="graph-blocks">
                                                                                <ul className="graph-list">
                                                                                    {(totalCount?.searchedGenre || []).map((item) => (
                                                                                        <li key={item.genre}>
                                                                                            <div className="graph-wrapper">

                                                                                                <p> {item.genre === "Documentary/DocuSeries" ? "Documentary / DocuSeries" : item.genre}</p>
                                                                                                <div className="graph-bar">
                                                                                                    <p className="count">{viewsCount(item.count)}</p>
                                                                                                    <div className="filled-graph" style={{ width: `${(item.count / totalCount.totalGeners) * 100}%` }}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}

                                                                                    {[...Array(5 - (totalCount?.searchedGenre?.length || 0))].map((_, index) => (
                                                                                        <li key={`empty-${index}`}>
                                                                                            <div className="graph-wrapper">
                                                                                                <p>NA</p>
                                                                                                <div className="graph-bar">
                                                                                                    <p className="count">0</p>
                                                                                                    <div className="filled-graph" style={{ width: `0%` }}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h6 className="widget-heading mb-4">TOP ClIENT COUNTRIES</h6>
                                                                            <div className="graph-blocks">
                                                                                <ul className="graph-list">
                                                                                    <ul className="graph-list">
                                                                                        {(totalCount?.searchedCountry || []).map((item, index) => (
                                                                                            <li key={index}>
                                                                                                <div className="graph-wrapper">
                                                                                                    <p>{item?.country}</p>
                                                                                                    <div className="graph-bar">
                                                                                                        <p className="count">{viewsCount(item?.count)}</p>
                                                                                                        <div className="filled-graph" style={{ width: `${(item.count / totalCount?.totalCountry) * 100}%` }}></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ))}

                                                                                        {[...Array(5 - (totalCount?.searchedCountry?.length || 0))].map((_, index) => (
                                                                                            <li key={`empty-${index}`}>
                                                                                                <div className="graph-wrapper">
                                                                                                    <p>NA</p>
                                                                                                    <div className="graph-bar">
                                                                                                        <p className="count">0</p>
                                                                                                        <div className="filled-graph" style={{ width: `0%` }}></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>

                                                                                </ul>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end card--> */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-lg-3">
                                                            <div className="card card_height">
                                                                <div className="card-body">
                                                                    <h6 className="widget-heading mb-2">Content Summary</h6>
                                                                    <table className="table table-striped summary-table mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className="text-left">Status</th>
                                                                                <th scope="col" className="text-right">Count</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr >
                                                                                <td scope="row" className="text-left">Inactive</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.content?.INACTIVE) ? viewsCount(totalCount?.content?.INACTIVE) : 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Active</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.content?.ACTIVE) ? viewsCount(totalCount?.content?.ACTIVE) : 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">In Progress</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.content?.INPROGRESS) ? viewsCount(totalCount?.content?.INPROGRESS) : 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Offline</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.content?.OFFLINE) ? viewsCount(totalCount?.content?.OFFLINE) : 0}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Rejected</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.content?.REJECTED) ? viewsCount(totalCount?.content?.REJECTED) : 0}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <h6 className="widget-heading mb-2 mt-4">Deals Summary</h6>
                                                                    <table className="table table-striped summary-table mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className="text-left">Status</th>
                                                                                <th scope="col" className="text-right">Count</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr >
                                                                                <td scope="row" className="text-left">New</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.deal?.new)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">In progress</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.deal?.inprogress)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Partially Closed</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.deal?.partiallyClosed)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Closed</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.deal?.closed)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td scope="row" className="text-left">Successfully Closed</td>
                                                                                <td className="text-right">{viewsCount(totalCount?.deal?.successfullyClosed)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            {/* <!--end card--> */}

                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--end row--> */}

                            {/* <!--end row--> */}

                        </div>

                    </div>





                    <Footer />
                </div>

            </div>
        </>
    );
};

export default DashBoard;
